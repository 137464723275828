import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

const theme = extendTheme({
  styles: {
    global: props => ({
      body: {
        bg: mode("brand.light.shade", "brand.dark.shade")(props),
        color: mode("brand.dark.shade", "brand.light.shade")(props),
      },
      a: {
        color: mode("brand.primary", "brand.primary")(props),
      },
    }),
  },
  fonts: {
    body:
      "'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
    heading: "'Merriweather', Georgia, Serif",
    monospace: `"Operator Mono", Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
  },
  colors: {
    brand: {
      primary: "#AC4A31",
      light: {
        accent: "#BA9989",
        shade: "#ECEBE1",
      },
      dark: {
        accent: "#CA9144",
        shade: "#142643",
      },
    },
  },
})
export default theme
